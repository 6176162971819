import React from "react"
import {
    ReactVideoPlayer
} from "../components/reactPlayer"

 const fixture = [
    {
        citationName: 'Lisa Jennings',
        citationLocation: 'London',
        quoteDescription: 'I\'ve used Permissioning and Transformation™ to treat patients who are suffering from anxiety. I\'ve seen it help alleviate overthinking and mental stress very, very rapidly. I don\'t know any other technique that\'s easier or more effective.',
        videoUrl: <ReactVideoPlayer 
            videoURL="https://www.youtube.com/embed/8camKK3LJo8"
            title="Auror Quantum-Touch Level 1 testimonial"
            />
    },
    {
        citationName: 'Kully',
        citationLocation: 'London UK',
        quoteDescription: 'I\'ve been using the program for 2 months now and my sleep continues to improve. Even thinking about the techniques helps trigger me falling asleep. I would definitely recommend this, it\'s easy and is literally just listening to a process on a couple of audios. What could be more simple than that?',
        videoUrl: <ReactVideoPlayer 
            videoURL="https://www.youtube.com/embed/OyyVyzP1ZaU"
            title="Auror Quantum-Touch Level 1 testimonial"
            // placeholder="https://karinagrant.s3.eu-west-1.amazonaws.com/images/aynoa-lecea-placeholder.jpg"
            />
    }

] 
export default fixture