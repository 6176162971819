import React from "react"
import {
    ReactVideoPlayer
} from "../components/reactPlayer"

 const fixture = [
    {
        citationName: 'Jovita Bidviene, London',
        citationLocation: 'London, UK',
        quoteDescription: 'In the workshop one of my slipped discs was healed with Quantum-Touch',
        videoUrl: <ReactVideoPlayer 
            videoURL="https://youtu.be/1Yrf_SP1y1E"
            title="Jovita tells us about her experience in the Quantum-Touch workshop"
            // placeholder="https://karinagrant.s3.eu-west-1.amazonaws.com/images/raine-self-created-health.jpg"
            />
    },
    {
        citationName: 'Raine',
        citationLocation: 'South Africa',
        quoteDescription: 'After 3 sessions of Self Created Health - 4 years of chronic fatigue gone!',
        videoUrl: <ReactVideoPlayer 
            videoURL="https://www.youtube.com/embed/zA3rOkoLxzk"
            title="Raine tells us about her experience of Self Created Health"
            // placeholder="https://karinagrant.s3.eu-west-1.amazonaws.com/images/raine-self-created-health.jpg"
            />
    },
    {
        citationName: 'Claire Green',
        citationLocation: 'Exeter UK',
        quoteDescription: 'How Claire a NHS respiratory nurse uses Quantum-Touch with her patients',
        videoUrl: <ReactVideoPlayer 
            videoURL="https://www.youtube.com/embed/McB69gAjgLE"
            title="Claire Green Quantum-Touch Level 1 testimonial"
            // placeholder="https://karinagrant.s3.eu-west-1.amazonaws.com/images/claire-green-placeholder.png"
            />
    },
    {
        citationName: 'Aynoa Lecea',
        citationLocation: 'Exeter UK',
        quoteDescription: 'Life Saving experience With Quantum-Touch',
        videoUrl: <ReactVideoPlayer 
            videoURL="https://www.youtube.com/embed/kVvRCItdff0"
            title="Aynoa Lecea a Quantum-Touch student sharing her experience with Quantum-Touch"
            // placeholder="https://karinagrant.s3.eu-west-1.amazonaws.com/images/aynoa-lecea-placeholder.jpg"
            />
    },
    {
        citationName: 'Ceri',
        citationLocation: 'Wales',
        quoteDescription: 'At the Big Retreat a volunteer called Ceri put her hand up. She came to the front and told us all that she had daily pain for 13 years since her daughter’s birth. Quantum-Touch helps her pain.',
        videoUrl: <ReactVideoPlayer 
            videoURL="https://www.youtube.com/embed/uS5q5uQCHv0"
            title="A wonderful Welsh lady called Ceri put her hand up. She came to the front and told us all that she had daily pain for 13 years since her daughter’s birth"
            // placeholder="https://karinagrant.s3.eu-west-1.amazonaws.com/images/aynoa-lecea-placeholder.jpg"
            />
    },
    {
        citationName: 'Joanna',
        citationLocation: 'London',
        quoteDescription: 'Debilitating pain from Fibromyalgia to being pain free',
        videoUrl: <ReactVideoPlayer 
            videoURL="https://www.youtube.com/embed/-ITgyXNwHn4"
            title="Joanna's first Quantum-Touch session with me was in 2013 and I have been overjoyed to watch her go from being in debilitating pain from Fibromyalgia to being pain free."
            // placeholder="https://karinagrant.s3.eu-west-1.amazonaws.com/images/raine-dunn-testimonial-quantum-touch-placeholder.jpg"
            />
    },
    {
        citationName: 'Raine Dunn',
        citationLocation: 'South Africa',
        quoteDescription: 'Quantum-Touch helps Raine and her 8 year old son',
        videoUrl: <ReactVideoPlayer 
            videoURL="https://www.youtube.com/embed/i85vl8wBQ4s"
            title="Raines Quantum-Touch Level 1 testimonial"
            // placeholder="https://karinagrant.s3.eu-west-1.amazonaws.com/images/raine-dunn-testimonial-quantum-touch-placeholder.jpg"
            />
    },

    
    {
        citationName: 'Auror',
        citationLocation: 'London',
        // quoteTitle: 'Auror Quantum-Touch Level 1 testimonial',
        quoteDescription: 'Watch Auror\'s video feedback from taking a Quantum-Touch Level 1 workshop online',
        videoUrl: <ReactVideoPlayer 
            videoURL="https://www.youtube.com/embed/CwOHJZ8pxzg"
            title="Auror Quantum-Touch Level 1 testimonial"
            // placeholder="https://karinagrant.s3.eu-west-1.amazonaws.com/images/level-1-aurore-screenshot-min.jpg"
            />
    },
    
    
    {
        citationName: 'Marie Lohan',
        citationLocation: 'London',
        quoteDescription: 'Quantum-Touch Level 2 stops the shaking and trembling in an epileptic seizure',
        videoUrl: <ReactVideoPlayer 
            videoURL="https://www.youtube.com/embed/vYK_8XWHSxI"
            title="Claire Green Quantum-Touch Level 2 testimonial"
            // placeholder="https://karinagrant.s3.eu-west-1.amazonaws.com/images/claire-green-placeholder.png"
            />
    },

    
    
    
    
    

    

] 
export default fixture