import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import Sticky from 'react-sticky-el'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import {
  Article,
  ArticleHeader,
} from "../styledComponents/article"
import { TestimonialsSection } from "../styledComponents/testimonials"
import SEO from "../components/seo"
import ClientTestimonials from "../fixtures/testimonial-clients"
import QtLeve1Testimonials from "../fixtures/testimonial-qt-level-1"
import QtLeve2Testimonials from "../fixtures/testimonial-qt-level-2"
import VideoTestimonialsQt from "../fixtures/testimonial-video-qt"
import VideoTestimonialsPermissioning from "../fixtures/testimonial-video-permissioning"
import SelfCreatedHealthTestimonials from "../fixtures/testimonial-self-created-health"
import PermissioningTestimonials from "../fixtures/testimonial-permissioning"
import TestimonialListItem from "../components/testimonialListItem"
import HeroBanner from "../components/HeroBanner"
import { HeroText } from "../styledComponents/heroBanner"
import { StyledAnchorLink } from "../styledComponents/button"
import Testimonials from "../components/testimonials"
import LayoutNoStripe from "../components/layoutNoStripe"


const TestimonialsPage = ({ data, location }) => {

  const [tabIndex, setTabIndex] = useState(0);
  const scrollToTabs = () => {
    document.getElementById('tabs').scrollIntoView({
      behavior: 'smooth'
    });
  }
  const checkIfHasQuery = () => {
    const urlParams = new URLSearchParams(window.location.search)
    if(urlParams.has("client")){
      setTabIndex(0)
      scrollToTabs()
    }
    if(urlParams.has("video")){
      setTabIndex(1)
      scrollToTabs()
    }
    if(urlParams.has("level-1")){
      setTabIndex(2)
      scrollToTabs()
    }
    if(urlParams.has("level-2")){
      setTabIndex(3)
      scrollToTabs()
    }
    if(urlParams.has("self-created-health")){
      setTabIndex(4)
      scrollToTabs()
    }
    if(urlParams.has("permissioning")){
      setTabIndex(5)
      scrollToTabs()
    }
  }

  useEffect(() => {
    checkIfHasQuery()
  }, [])

  return (
    <LayoutNoStripe displayNavBorder location={location}>
      <SEO
        title='Quantum-Touch Reviews & Testimonials from clients and past students'
        description="Testimonials from Karina's clients and students who have attended hewr workshops and events. Watch what other students have to say on Karina's workshops."
        url="/testimonials"
      />

      <HeroBanner
        image="/images/backgrounds/testimonials.jpg"
        backgroundPosition="0 0"
        largeBackgroundPosition="0 -260px"
        largeBackgroundSize="100%"
        type="testimonial"
      >
        <Testimonials
          data={data}
          showTestimonialsSectionHeader={false}
          showMoreTestimonialsButton={false}
          showMoreTestimonialsButtonLink="/testimonials"
          showMoreTestimonialsButtonText="Read more testimonials"
          showDots={true}
          isInsideHero={true}
        />
        <HeroText>
          <StyledAnchorLink transparent={true} className="testimonial-anchor" to="/testimonials#more">Read more testimonials</StyledAnchorLink>
        </HeroText>
      </HeroBanner>
      <Article itemScope itemType="http://schema.org/Article" id="more">
        <ArticleHeader>
          <h1 itemProp="headline" className="offscreen">Testimonials</h1>
        </ArticleHeader>


        <>
          <TestimonialsSection itemProp="articleBody" noPadding mainTestionialsPage={true}>
            {/* {parse(pageContent)} */}

            <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)} className="testimonial-tabs" id='tabs'> 
              <Sticky>
                <TabList>
                  <Tab>Client</Tab>
                  <Tab>Video</Tab>
                  <Tab><abbr title="Quantum-Touch">QT</abbr> Level 1</Tab>
                  <Tab><abbr title="Quantum-Touch">QT</abbr> Level 2</Tab>
                  <Tab>Self Created Health</Tab>
                  <Tab>Permissioning &amp; Transformation™</Tab>
                </TabList>
              </Sticky>
              <TabPanel>
                <ul className="testimonials">
                  {ClientTestimonials &&
                    <>
                      {
                        ClientTestimonials.map((quotes, index) => {
                          return (
                            <TestimonialListItem quotes={quotes} />
                          )
                        })
                      }
                    </>
                  }
                </ul>
              </TabPanel>
              <TabPanel className="videos">
                <Tabs className="testimonial-tabs" style={{'margin-top':'1.6rem'}}>
                  <TabList>
                    <Tab>Quantum-Touch</Tab>
                    <Tab>Permissioning™</Tab>
                  </TabList>
                  <TabPanel>
                    <ul className="testimonials">
                      {VideoTestimonialsQt &&
                        <>
                          {
                            VideoTestimonialsQt.map((quotes, index) => {
                              return (
                                <TestimonialListItem quotes={quotes} />
                              )
                            })
                          }
                        </>
                      }
                    </ul>
                  </TabPanel>
                  <TabPanel>
                    <ul className="testimonials">
                      {VideoTestimonialsPermissioning &&
                        <>
                          {
                            VideoTestimonialsPermissioning.map((quotes, index) => {
                              return (
                                <TestimonialListItem quotes={quotes} />
                              )
                            })
                          }
                        </>
                      }
                    </ul>
                  </TabPanel>
                </Tabs>
              </TabPanel>
              <TabPanel>
                <ul className="testimonials">
                  {QtLeve1Testimonials &&
                    <>
                      {
                        QtLeve1Testimonials.map((quotes, index) => {
                          return (
                            <TestimonialListItem quotes={quotes} />
                          )
                        })
                      }
                    </>
                  }
                </ul>
              </TabPanel>
              <TabPanel>
                <ul className="testimonials">
                  {QtLeve2Testimonials &&
                    <>
                      {
                        QtLeve2Testimonials.map((quotes, index) => {
                          return (
                            <TestimonialListItem quotes={quotes} />
                          )
                        })
                      }
                    </>
                  }
                </ul>
              </TabPanel>
              <TabPanel>
                <ul className="testimonials">
                  {SelfCreatedHealthTestimonials &&
                    <>
                      {
                        SelfCreatedHealthTestimonials.map((quotes, index) => {
                          return (
                            <TestimonialListItem quotes={quotes} />
                          )
                        })
                      }
                    </>
                  }
                </ul>
              </TabPanel>
              <TabPanel>

                <ul className="testimonials">
                  {PermissioningTestimonials &&
                    <>
                      {
                        PermissioningTestimonials.map((quotes, index) => {
                          return (
                            <TestimonialListItem quotes={quotes} />
                          )
                        })
                      }
                    </>
                  }
                </ul>
              </TabPanel>
            </Tabs>
          </TestimonialsSection>
        </>
      </Article>
    </LayoutNoStripe>
  )
}

export default TestimonialsPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    wpPage(slug: { eq: "homepage" }) {
      title
      slug
      content
      testimonials {
        blockquote {
          citeLocation
          citationName
          quote
        }
        testimonialHeading
        testimonialLede
      }
    }

    videoTestimonials: wpPage(slug: { eq: "video-testimonials" }) {
      title
      content
    }

    clientTestimonials: wpPage(slug: { eq: "client-testimonials" }) {
      title
      content
    }

    level1Testimonials: wpPage(slug: { eq: "quantum-touch-level-1-testimonials" }) {
      title
      content
    }

    level2Testimonials: wpPage(slug: { eq: "quantum-touch-level-2-testimonials" }) {
      title
      content
    }

    schTestimonials: wpPage(slug: { eq: "self-created-health-testimonials" }) {
      title
      content
    }

    permissioningTestimonials: wpPage(slug: { eq: "permissioning-transformation-testimonials" }) {
      title
      content
    }


  }
`
